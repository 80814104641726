<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">나의 코멘트</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>문서 원복 사유</th>
            <td>
              <Textarea
                style="height: 96px"
                placeholder="문서 원복 사유를 입력해주세요"
                :max="300"
                :value.sync="commentSync"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Textarea from "@/_approval/components/common/textarea/Textarea";

export default {
  name: "DocumentRestoreReason",
  components: {
    Textarea,
  },
  props: {
    comment: String,
  },
  computed: {
    commentSync: {
      get() {
        return this.comment;
      },
      set(value) {
        this.$emit("update:comment", value);
      },
    },
  },
};
</script>
